<template>
    <div class="detail_box" v-loading="loading">
        <div class="order_no">
            提单号：{{ info.bl_no || '-' }}
        </div>
        <div class="car_info">
            <div class="title">
                集装箱信息
            </div>
            <div class="infoList">

                <!-- <div class="infoItem">
                    <div class="itemFl">
                        提单号：
                    </div>
                    <div class="itemFr">
                        {{info.bl_no || '-'}}
                    </div>
                </div> -->
                <div class="infoItem">
                    <div class="itemFl">
                        船名：
                    </div>
                    <div class="itemFr">
                        {{ info.vessel || '-' }}
                    </div>
                </div>
                <div class="infoItem">
                    <div class="itemFl">
                        航次：
                    </div>
                    <div class="itemFr">
                        {{ info.voyage_flight || '-' }}
                    </div>
                </div>
                <div class="infoItem">
                    <div class="itemFl">
                        目的港：
                    </div>
                    <div class="itemFr">
                        {{ info.pod_info && info.pod_info.name_en || '-' }}
                    </div>
                </div>
                <div class="infoItem">
                    <div class="itemFl">
                        箱型：
                    </div>
                    <div class="itemFr">
                        {{ info.box_type || '-' }}
                    </div>
                </div>
                <div class="infoItem">
                    <div class="itemFl">
                        箱号：
                    </div>
                    <div class="itemFr">
                        {{ info.box_no || '-' }}
                    </div>
                </div>
                <div class="infoItem">
                    <div class="itemFl">
                        封号：
                    </div>
                    <div class="itemFr">
                        {{ info.title || '-' }}
                    </div>
                </div>
                <div class="infoItem">
                    <div class="itemFl">
                        体积：
                    </div>
                    <div class="itemFr">
                        {{ info.volume }}
                    </div>
                </div>
                <div class="infoItem">
                    <div class="itemFl">
                        箱皮重：
                    </div>
                    <div class="itemFr">
                        {{ info.tare_weight }}
                    </div>
                </div>
                <div class="infoItem">
                    <div class="itemFl">
                        装箱人员：
                    </div>
                    <div class="itemFr">
                        {{ info.packing_user_name }}
                    </div>
                </div>
                <div class="infoItem">
                    <div class="itemFl">
                        完成时间：
                    </div>
                    <div class="itemFr">
                        {{ info.packing_completed | secondFormat() }}
                    </div>
                </div>


                <div class="infoItem">
                    <div class="itemFl">
                        状态：
                    </div>
                    <div class="itemFr">
                        <el-tag type="warning" v-if="info.status == 1">待装</el-tag>
                        <el-tag v-if="info.status == 2">操作</el-tag>
                        <el-tag type="success" v-if="info.status == 3">已装</el-tag>
                    </div>
                </div>
            </div>
        </div>

        <div class="car_info">
            <div class="title">
                车辆信息 
            </div>
            <el-table :data="info.vehicles_list" style="width: 100%" header-cell-class-name="table_header">
                <el-table-column type="index" width="50">
                </el-table-column>
                <el-table-column prop="bl_no" label="提单号">

                </el-table-column>
                <el-table-column prop="vin" label="VIN码">
                    <template slot-scope="scope">
                        <span> {{ scope.row.vin
                        }}</span>
                    </template>
                </el-table-column>

                <el-table-column prop="in_wh_time" label="入库时间">
                    <template slot-scope="scope">
                        {{ scope.row.in_wh_time | secondFormat() }}
                    </template>
                </el-table-column>

                <el-table-column prop="in_wh_time" label="装箱时间">
                    <template slot-scope="scope">
                        {{ scope.row.packing_time | secondFormat() }}
                    </template>
                </el-table-column>
                <el-table-column prop="box_no" label="箱号" />
                <el-table-column prop="customer_name_zh" label="委托单位" />
                <el-table-column prop="brand" label="品牌" />
                <el-table-column prop="vehicle_model" label="品牌" />
                <el-table-column prop="vehicle_config" label="配置" />
                <el-table-column prop="weight" label="重量（KG）" />
                <el-table-column prop="vehicle_engine" label="发动机" />

            </el-table>

        </div>
        <div class="car_info">
            <div class="title">
                装箱照片 <el-button size="small" type="primary" @click="downImg" style="margin-left: 20px;"
                    :loading="btnLoading" v-if="info.imgs && info.imgs.length">下载全部照片</el-button>
            </div>
            <div class="imgList" v-if="info.imgs && info.imgs.length">
                <el-image class="img" v-for="(item, index) in info.imgs" :key="index" style="width: 140px; height: 140px"
                    :src="item" fit="cover" :preview-src-list="info.imgs">
                </el-image>
            </div>
            <div class="noData" v-else>
                暂无图片
            </div>
        </div>
    </div>
</template>
  
<script>

export default {
    components: {

    },
    data() {
        return {
            info: {},
            btnLoading: false,
            loading: false
        }
    },
    mounted() {
        this.getCarDetaila()
    },
    methods: {
        async getCarDetaila() {
            this.loading = true
            let res = await this.$store.dispatch("API_warehouse/getBoxDetaila", {
                id: this.$route.query.id
            });
            this.loading = false
            if (res.success) {
                res.data.imgs = res.data.packing_image.map(ee => { return ee.url })
                this.info = res.data
            }
        },
        async downImg() {
            this.btnLoading = true
            let data = await this.$store.dispatch('API_warehouse/downImg', {
                files_url: JSON.stringify(this.info.imgs),
                download_source: '装箱照片'
            })
            window.open(data.data)
            this.btnLoading = false
        },
    }

}
</script>
  
<style lang="less" scoped>
/deep/ .el-button--primary {
    background: #91be42 !important;
    border: none;
    margin-left: 20px;
}

.noData {
    line-height: 150px;
    text-align: center;
    font-size: 14px;
    color: #999;
}

.imgList {
    padding: 30px 30px;
    min-height: 100px;

    .img {
        margin: 5px 5px;
    }
}

/deep/ .table_header {
    background: #91be42;

    .cell {
        color: #FFF;
    }
}


.detail_box {
    width: 100%;
    min-height: calc(100vh - 129px);
    margin: 0 auto;
    background: #FBFCFA;
    padding: 30px 0;

    .order_no {
        width: 1300px;
        padding: 30px 25px;
        background: #FFF;
        margin: 0px auto 30px;
        font-size: 16px;
        font-weight: 600;
    }

}

.car_info {
    width: 1300px;
    margin: 30px auto;
    background: #FFF;

    .title {
        font-size: 16px;
        line-height: 60px;
        text-indent: 20px;
        font-weight: 600;
        border-bottom: 1px solid #eee;
    }
}

.infoList {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 0;

    .infoItem {
        width: 50%;
        padding: 0 20px;
        margin: 10px 0;
        display: flex;
        align-items: center;

        .itemFl {
            width: 90px;
        }
    }
}</style>